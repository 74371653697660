@import "reset";
@import "partials/colors";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

$auth-border-radius: 3px;

body {
  font-family: Arial;
  font-weight: lighter;
  background: $white;
}

.container {
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
}

.logo {
  text-align: center;
  margin-bottom: 25px;

  img {
    max-width: 210px;
  }
}

.auth {
  width: 400px;
  height: 300px;
  background-color: #f2f2f2;
}

.auth__header {
  border-top-left-radius: $auth-border-radius;
  border-top-right-radius: $auth-border-radius;
  background: #ff8d0d;
  background: $pier-red;
  padding: 25px 25px 22px 25px;
  border-bottom: 1px solid $white;
}

.auth__title {
  padding: 0;
  margin: 0;
  color: $white;
  font-size: 1rem;
  text-transform: uppercase;
  text-shadow: #ff3d0d 1px 1px 1px;
}

.auth__body { padding: 10px 25px 35px 25px; }

.form__group {
  position: relative;
  padding: 10px 0;
}

.form__label { display: none; }

.form__input {
  font-family: Arial;
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 2px;
  background-color: #f0eef0;
  outline: none;
  color: #5c5c5c;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 0px 1px #fafafa;
  font-size: 0.9rem;
  padding: 3px 55px 0 25px;
}

.form__icon {
  position: absolute;
  z-index: 10;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: #8e8e8e;
  font-size: 0.9rem;
}

.form__submit {
  border: 0;
  outline: 0;
  background: $pier-red;
  color: $white;
  border-radius: $auth-border-radius;
  text-shadow: #ff3d0d 1px 1px 1px;
  padding: 11px 18px;
  font-size: 0.9rem;
  cursor: pointer;
}
